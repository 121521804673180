import verificaSeScriptExiste from "../utilitarios/verifica-se-script-existe.js";


verificaSeScriptExiste({
    prop      : 'uol',
    callback  : createPagseguroSystem,
    scriptUrl : 'https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js'
});

function createPagseguroSystem() {
    $('#tabs-pagamento li a:first').trigger('click');

    window.escolherPagamento = escolherPagamento;

    function escolherPagamento(payment) {
        $.ajax({
            url: 'index.php?route=checkout/payment_method/save',
            type: 'post',
            data: 'payment_method=' + payment + '&comment=',
            dataType: 'json',
            beforeSend: function() {
                $('.btn--finaliza-compra:focus').after('<span class="wait"><i class="fa fa-cog fa-spin text-muted fa-2x"></i></span>');
                $('.btn--finaliza-compra').attr('disabled', true);
            },
            complete: function() {
                // $('.btn--finaliza-compra').attr('disabled', false);
            },
            success: function(json) {
                finalizarCompra(json, payment);
            },
            error: function(xhr, ajaxOptions, thrownError) {
                $('.btn--finaliza-compra').attr('disabled', false);
                $('.wait').remove();
                alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
        return false;
    }
    function finalizarCompra(json, code) {
        $('.alert').remove();

        if (json['redirect']) {
            location = json['redirect'];
        } else if (json['error']) {
            if (json['error']['warning']) {
                $('#'+code).prepend('<div class="alert alert-warning" style="display: none;">' + json['error']['warning'] + '<i class="close fa fa-times"></i></div>');

                $('#'+code).fadeIn('slow');
            }
        } else {
            $.ajax({
                url: 'index.php?route=checkout/confirm',
                dataType: 'html',
                success: function(html) {
                    $('#'+code).html(html);
                },
                error: function(xhr, ajaxOptions, thrownError) {
                    // $('[data-action="finaliza.compra"]').attr('disabled', false);

                    $('.btn--finaliza-compra, [data-action="finaliza.compra"]').attr('disabled', false);
                    $('.wait').remove();
                    alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
                }
            });
        }
    }
    function clique() {}
}