import notification from "../utilitarios/notification.js";
import Loader from '../utilitarios/loader.js'

const $url = 'index.php?route=checkout/cart'

function checkQuantidade(quantity) {
	quantity = isNaN(quantity) ? 1 : quantity;
}

const cart = {
	'add': function(product_id, quantity) {

		checkQuantidade(quantity);

		$.ajax({
			url: `${$url}/add`,
			type: 'post',
			data: `product_id=${product_id}&quantity=${quantity}`,
			dataType: 'json',
			beforeSend: function() {
				$('#cart > button').button('loading');
			},
			complete: function() {
				$('#cart > button').button('reset');
			},
			success: function(json) {
				$('.alert, .text-danger').remove();

				if (json['redirect']) {
					location = json['redirect'];
				}

				if (json['success']) {
					//notification.success(json['success']);

					/*window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });*/

					$('#notificacao').append(
						'<div class="alerta__custom alerta-success alert">\n' +
						'        <button class="close" type="button" data-dismiss="alert">&times;</button>\n' +
						'        <p>'+ json['success'] +'</p>\n' +
						'</div>'
					);

					if (json['count']) {
						$('#box-carrinho [data-cart="count"]').text(json['count']);
					}
					if (json['total']) {
						$('#box-carrinho [data-cart="total"]').text(json['total']);
					}


				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
		return false;
	},
	'update': function(key, quantity) {
		checkQuantidade(quantity);

		$.ajax({
			url: `${$url}/edit`,
			type: 'post',
			data: `key=${key}&quantity[${key}]=${quantity}`,
			beforeSend: function() {
				Loader.on();
			},
			success: function(json) {
				location = $url;
			},
			error: function(xhr, ajaxOptions, thrownError) {
				Loader.off();
				location = $url;
			}
		});
	},
	'remove': function(key) {
		$.ajax({
			url: `${$url}/remove`,
			type: 'post',
			data: `key=${key}`,
			success: function(json) {
				location = $url;
			},
			error: function(xhr, ajaxOptions, thrownError) {
				location = $url;
			}
		});
	}
}

window.cart = cart;